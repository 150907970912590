enum Status {
    /**
     * Extrayendo datos
     */
    EXTRACT_DATA = "extrayendo-datos",
    /**
     * Error extrayendo datos
     */
    ERROR_EXTRACT_DATA = "error-extrayendo-datos",
    /**
     * Cotejar documentos 
     */
    VERIFICATION = "cotejo",
    /**
     * Validando datos del fiado
     */
    VALIDATING_GUARANTOR = "validando-fiado",
    /**
     * Fiado invalido
     */
    INVALID_GUARANTOR = "fiado-invalido",
    /**
     * Error en la validación del fiado
     */
    GUARANTOR_VALIDATION_ERROR = "error-validacion-fiado",
    /**
     * Creando solicitud
     */
    CREATING_REQUEST = "creando-solicitud",
    /**
     * Fiado duplicado
     */
    DUPLICATE_GUARANTOR = "fiado-duplicado",
    /**
     * Error en la creación de la solicitud
     */
    CREATING_REQUEST_ERROR = "error-creando-solicitud",
    /**
     * Revisión del contrato
     */
    CONTRACT_REVIEW = "revision-contrato",
    /**
     * Revision del contrato por parte del fiado
     */
    GUARANTOR_CONTRACT_REVIEW = "revision-contrato-fiado",
    /**
     * En proceso de firma
     */
    IN_SIGNING = "en-firma",
    /**
     * Solicitud de actualización de datos por parte del agente
     */
    UPDATING_REQUEST = "actualizando-solicitud",
    /**
     * Error en la actualización de la solicitud
     */
    UPDATING_REQUEST_ERROR = "error-actualizando-solicitud",
    /**
     * Alta completa
     */
    REGISTRATION_COMPLETE = "alta-completa",
    /**
     * Solicitud de actualización de datos por parte del fiado
     */
    GUARANTOR_CHANGE_REQUEST = "solicitud-cambio-fiado",
    /**
     * Error legado
     */
    ERROR_LEGACY = "error-legacy",
    /**
     * Solicitud de cambio de agente
     */
    AGENT_CHANGE_REQUEST = "solicitud-cambio-agente",
    /**
     * Error en la revisión del contrato del fiado
     */
    ERROR_GUARANTOR_CONTRACT_REVIEW = "error-revision-contrato-fiado",
    /**
     * Error en la solicitud de cambio de agente*
     * */
    ERROR_AGENT_CHANGE_REQUEST = "error-solicitud-cambio-agente",
    /**
     * Error en la solicitud de cambio por fiado
     * */
    ERROR_GUARANTOR_CHANGE_REQUEST = "error-solicitud-cambio-fiado",
    /**
     * Error en firma
     */
    ERROR_IN_SIGNING = "error-en-firma",
    /**
     * Validación de operación en proceso
     */
    CONTRACT_VALIDATION = "validacion-operacion-en-proceso",
    /**
     * Error en la validación de la operación
     */
    CONTRACT_VALIDATION_REJECT = "corregir-firma",
}
export default Status;

export interface Estado {

    message: string;
    isError: boolean;
}

export const estados: { [key: string]: Estado } = {
    [Status.EXTRACT_DATA]: {message: 'Extrayendo datos', isError: false },
    [Status.ERROR_EXTRACT_DATA]: {message: 'Error extrayendo datos', isError: true },
    [Status.VERIFICATION]: {message: 'En cotejo', isError: false },
    [Status.VALIDATING_GUARANTOR]: { message: 'Validando fiado', isError: false },
    [Status.INVALID_GUARANTOR]: { message: 'Fiado inválido', isError: true },
    [Status.GUARANTOR_VALIDATION_ERROR]: { message: 'Error en la validación del fiado', isError: true },
    [Status.CREATING_REQUEST]: { message: 'Solicitud en creación', isError: false },
    [Status.DUPLICATE_GUARANTOR]: { message: 'Fiado ya existe', isError: true },
    [Status.CREATING_REQUEST_ERROR]: { message: 'Error en la creación de la solicitud', isError: true },
    [Status.CONTRACT_REVIEW]: { message: 'En revisión agente', isError: false },
    [Status.GUARANTOR_CONTRACT_REVIEW]: { message: 'En revisión fiado', isError: false },
    [Status.IN_SIGNING]: { message: 'En proceso de firma', isError: false },
    [Status.UPDATING_REQUEST]: { message: 'Actualizando solicitud', isError: false },
    [Status.UPDATING_REQUEST_ERROR]: { message: 'Error en la actualización de la solicitud', isError: true },
    [Status.REGISTRATION_COMPLETE]: { message: 'Alta completa', isError: false },
    [Status.GUARANTOR_CHANGE_REQUEST]: { message: 'Solicitud de cambio por fiado', isError: false },
    [Status.AGENT_CHANGE_REQUEST]: { message: 'Solicitud de cambio por agente', isError: false },
    [Status.ERROR_LEGACY]: { message: 'Error en versiones anteriores de sistema', isError: true },
    [Status.ERROR_GUARANTOR_CONTRACT_REVIEW]: { message: 'Error en revisión contrato fiado', isError: true },
    [Status.ERROR_IN_SIGNING]: { message: 'Error en firma', isError: true },
    [Status.ERROR_AGENT_CHANGE_REQUEST]: { message: 'Error cambiando datos', isError: true },
    [Status.ERROR_GUARANTOR_CHANGE_REQUEST]: { message: 'Error en solicitud de cambio por fiado', isError: true },
    [Status.CONTRACT_VALIDATION]: { message: 'Validación de operación en proceso', isError: false },
    [Status.CONTRACT_VALIDATION_REJECT]: { message: 'Corregir firma', isError: true },
};

export const statusManualSigned: String[] = [
    Status.CONTRACT_REVIEW,
    Status.CONTRACT_VALIDATION_REJECT,
]