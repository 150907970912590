  export enum FinancialStatementsStatus {
    EXTRACT_DATA = "extrayendo-datos",
    ERROR_EXTRACT_DATA = "error-extrayendo-datos",
    IN_AGENT_REVIEW = "revision-agente",
    REQUEST_CREATION = "solicitud-en-creacion",
    ERROR_REQUEST_CREATION = "error-solicitud-en-creacion",
    REQUEST_COMPLETE = "solicitud-completa",
    IN_OPERATION_REVIEW = "revision-operaciones",
    VALIDACION_OPERACIONES ="validacion-operacion-en-proceso",
    ERROR_OPERATION = "error-revision-operaciones",
    CORRECT_INFO = "corregir-informacion",
    ERROR_CORRECT_INFO = "error-corregir-informacion",
    VALIDANDO_FIADO= "validando-fiado",
    ERROR_AGENT_REVIEW  =  "error-revision-agente",
    DUPLICATE_REQUEST = "solicitud-modificacion-duplicada",
    DUPLICATE_REQUEST_CURRENT_DOCUMENT = "solicitud-modificacion-documento-vigente"
  }
  
  export interface FinancialStatementsState {
    message: string;
    isError: boolean;
    color: string;
  }
  
  export const financialStatementsStates: Record<FinancialStatementsStatus, FinancialStatementsState> = {
    [FinancialStatementsStatus.EXTRACT_DATA]: { message: 'Extrayendo datos', isError: false, color: '#92D050' },
    [FinancialStatementsStatus.ERROR_EXTRACT_DATA]: { message: 'Error extrayendo datos', isError: true, color: '#FFC000' },
    [FinancialStatementsStatus.IN_AGENT_REVIEW]: { message: 'En revisión agente', isError: false, color: '#00B0F0' },
    [FinancialStatementsStatus.REQUEST_CREATION]: { message: 'Solicitud en creación', isError: false, color: '#E599F7' },
    [FinancialStatementsStatus.ERROR_REQUEST_CREATION]: { message: 'Error en creación de solicitud', isError: true, color: '#FF0000' },
    [FinancialStatementsStatus.REQUEST_COMPLETE]: { message: 'Solicitud completa', isError: false, color: '#92D050' },
    [FinancialStatementsStatus.IN_OPERATION_REVIEW]: { message: 'En revisión operaciones', isError: false, color: '#00B0F0' },
    [FinancialStatementsStatus.ERROR_OPERATION]: { message: 'Error en operación', isError: true, color: '#FF0000' },
    [FinancialStatementsStatus.CORRECT_INFO]: { message: 'Corregir información', isError: false, color: '#0070C0' },
    [FinancialStatementsStatus.ERROR_CORRECT_INFO]: { message: 'Error corregir información', isError: true, color: '#FFC000' },
    [FinancialStatementsStatus.VALIDANDO_FIADO]: { message: 'Validando Fiado', isError: false, color: '#92D050' },
    [FinancialStatementsStatus.VALIDACION_OPERACIONES]: { message: 'En revisión operaciones', isError: false, color: '#92D050' },
    [FinancialStatementsStatus.ERROR_AGENT_REVIEW]: { message: 'Error en revisión agente', isError: true, color: '#FF0000' },
    [FinancialStatementsStatus.DUPLICATE_REQUEST]: { message: 'En revisión agente', isError: true, color: '#FF0000' },  
    [FinancialStatementsStatus.DUPLICATE_REQUEST_CURRENT_DOCUMENT]: { message: 'En revisión agente', isError: true, color: '#FF0000' }
  };
  